<template>
  <div>
    Orders By ASIN
  </div>
</template>

<script>
export default {
  name: 'OrdersByAsin',
}
</script>

<style scoped>

</style>
